import React, { Component } from 'react';
import { connect } from "react-redux";
import Things from "../../../ducks/Things";
import Modal from 'react-bootstrap4-modal';
import { bindActionCreators } from "redux";
import TextFieldGroup from "../../common/TextFieldGroup";
import './index.css';
import CSVReader from 'react-csv-reader';
import toastr from "toastr";
import * as _ from 'lodash';
import { Spinner } from '../../common/Spinner/index';
import { isAlphaNumeric, isAlpha } from '../../../validations/validator';
import { URL } from '../../../constants';

class VehicleAssignmentModal extends Component {

  constructor() {
    const groupData = JSON.parse(localStorage.getItem("groupInfo"));
    const company = JSON.parse(localStorage.getItem("companyInfo"));

    super();
    this.state = {
      things: [],
      map: {},
      invalidImeis: [],
      isLoading: true,
      groupDepthLevel: groupData.depthLevel,
      groupName: groupData.groupName,
      savingData: false,
      company: company
    }
  }


  async componentDidMount() {
    await this.props.actions.getUnAssignedThings();
    let map = {};
    _.forEach(this.props.things, item => {
      map[item.imei] = item.imei;
    });
    this.setState({ ...this.state, things: this.props.things, map, isLoading: false })
  }

  onChange = (e) => {
    console.log("e -", e.target)

    const index = e.target.id;

    let things = this.state.things;
    let item = things[index]
    item[e.target.name] = e.target.value;
    things[index] = item;
    this.setState({ ...this.state, things })

  };

  async onSubmit() {


    console.log("this s.tate. .tethijgn", this.state.things)


    try {
      this.setState({
        ...this.state,
        savingData: true
      })
      await this.props.actions.saveRecords({ data: this.state.things });
      toastr.success("Vehicle updated successfully!");
      this.setState({
        ...this.state,
        savingData: false
      })
      this.props.history.push("/vehicles/list");
    } catch (err) {
      this.setState({
        ...this.state,
        savingData: false
      })
      toastr.error(err.response.data.message);
    }
  }
  handleDarkSideForce() {
    toastr.error("Invalid file format!");
  }

  handleFileUpload(data) {

    if (data && data.length) {
      if (data.length <= 1) {
        return toastr.error("IMEI is missing in file.");
      }
      let heading = data[0];

      if (this.state.groupDepthLevel !== 7) {
        if (heading[0] !== 'IMEI' || heading[1] !== 'PLATE NUMBER' || heading[2] !== 'MAKE & MODEL' || heading[3] !== 'ODO METER READING' || heading[4] !== 'GROUP') {
          return toastr.error("Invalid file format!");
        }
      } else {
        if (heading[0] !== 'IMEI' || heading[1] !== 'PLATE NUMBER' || heading[2] !== 'MAKE & MODEL' || heading[3] !== 'ODO METER READING') {
          return toastr.error("Invalid file format!");
        }
      }

      let things = [];
      let invalidImeis = [];
      let haveInvalidPlateNumber = false;
      let haveInvalidModel = false;
      let haveInvalidODO = false;
      let invalidPlateNumbers = 'Invalid plate numbers: ';
      let invalidVehicleMakeAndModel = 'Invalid vehicel make and model for : ';
      let invalidOdoMeter = 'Invalid odo meter reading for: ';
      let invalidGroup = 'Invalid group for: ';

      _.forEach(data, (item, index) => {

        if (index !== 0) {

          let obj = { imei: '', plateNumber: '', odoMeterReading: 0, vehicleMakeAndModel: '', group: '', tags: '', valid: true, partyId: '', partyName: '', partyTerritory: '' };
          _.forEach(item, (value, valueIndex) => {
            if (valueIndex === 0) {
              obj.imei = _.trim(value);

              if (!this.state.map[value] || this.state.map[value] !== value) {
                invalidImeis.push(value);
              }
            }
            if (valueIndex === 1 && obj.valid) {
              if (value.length > 12 || !isAlphaNumeric(value)) {
                obj.valid = false;
                haveInvalidPlateNumber = true;
                invalidPlateNumbers = invalidPlateNumbers.concat(_.trim(obj.imei) + ", ")
                console.log(`Plate Number for ${obj.imei} must be alphanumeric and less than or equel to 12 characters.`);
              }
              obj.plateNumber = _.trim(value);
            }
            if (valueIndex === 2 && obj.valid) {
              if (value && (value.length > 20 || !isAlphaNumeric(value))) {
                obj.valid = false;
                haveInvalidModel = true;
                invalidVehicleMakeAndModel = invalidVehicleMakeAndModel.concat(obj.imei + ", ")
                console.log(`Make and model for ${obj.imei} must be alphanumeric and less than or equel to 20 characters.`);
              }
              obj.vehicleMakeAndModel = _.trim(value);;
            }
            if (valueIndex === 3 && obj.valid) {
              value = parseInt(value, 10);
              if (isNaN(value) || value < 0) {
                obj.valid = false;
                haveInvalidODO = true;
                invalidOdoMeter = invalidOdoMeter.concat(obj.imei + ", ")
                console.log("Invalid ODO meter for " + obj.imei);
              }
              obj.odoMeterReading = value;
            }
            if (valueIndex === 4) {
              if (value && (value.length > 30)) {
                invalidGroup = invalidGroup.concat(obj.imei + ", ");
                console.log("Invalid invalidGroup for " + value);
                console.log("Invalid invalidGroup lenghth " + value.length);
              }
              obj.group = this.state.groupDepthLevel !== 7 && this.props.createGroup ? _.trim(value) : this.state.groupName;
            }

            if (valueIndex === 5 && obj.valid) {
              obj.partyName = value || "N/A";
            }

            if (valueIndex === 6 && obj.valid) {
              obj.partyId = value || "N/A";
            }


            if (valueIndex === 7 && obj.valid) {
              obj.partyTerritory = value || "N/A";
            }

            if (!obj.imei && (obj.plateNumber || obj.vehicleMakeAndModel || obj.odoMeterReading || obj.group)) {
              console.error("IMEI is missing in file.");
              obj.valid = false;
            }
          })

          if (obj.imei && obj.valid) {
            things.push(obj);
          }
        }
      });

      if (haveInvalidPlateNumber) {
        toastr.error(invalidPlateNumbers)
      } else if (haveInvalidModel) {
        toastr.error(invalidVehicleMakeAndModel)
      } else if (haveInvalidODO) {
        toastr.error(invalidOdoMeter)
      }

      if (things.length) {
        this.setState({ ...this.state, things, invalidImeis });
      }

    }
  }

  navigateToVehicleList() {
    this.props.history.push("/vehicles/list");
  }

  navigateToHome() {
    this.props.history.push("/");
  }

  render() {
    let token = localStorage.getItem("refreshToken");
    const isLoadingRecords = this.state.isLoading;
    const hasData = this.state.things.length;
    const loader = <Spinner key="1" />
    return (

      <Modal visible={true} dialogClassName={'modal-lg ' + (!hasData ? 'no-data-model' : "")}>
        <div >
          <button type="button" className="close close-x" aria-label="Close" onClick={this.navigateToVehicleList.bind(this)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {!isLoadingRecords ?

          <div>
            {hasData ?
              <div>
                <div className='modal-heading'>

                  <h5 className="car-and-device-assig">Vehicle And Device Assignment</h5>
                  <p className="welcome-to-auxo-flee">Welcome to AUXO Fleet management solution, before continuing further please assign the devices IMEI numbers against their respective vehicle number plates.</p>

                  <div>
                    <span>
                      <a className="custom-vehicles-upload">

                        <CSVReader
                          cssClass="things-input-reader"
                          accept=".csv"
                          onFileLoaded={this.handleFileUpload.bind(this)}
                          onError={this.handleDarkSideForce}
                          inputId="ObiWan"
                        />
                        Import Vehicle Details
                      </a>
                    </span>
                    <span>
                      {this.state.groupDepthLevel !== 7 ?
                      this.state.company.companyId === "66fd17422e6f872ff9a43886" ? <a className="custom-vehicles-upload" href={`${URL}/companies/files/VehiclesDetailTemplateWarble.csv?token=${token}`} target="_blank" rel="noopener noreferrer">Download Template...</a> :
                        <a className="custom-vehicles-upload" href={`${URL}/companies/files/VehiclesDetailTemplate.csv?token=${token}`} target="_blank" rel="noopener noreferrer">Download Template</a> :
                        <a className="custom-vehicles-upload" href={`${URL}/companies/files/GroupVehiclesDetailTemplate.csv?token=${token}`} target="_blank" rel="noopener noreferrer">Download Template</a>}
                    </span>

                  </div>
                  {this.state.invalidImeis.length ? <p>Disclaimer: Vehicle information against device IMEIs will be updated if already exists: {this.state.invalidImeis}</p> : ''}

                </div>
                <div className="modal-body">
                  <table className="table-auxo">
                    <thead>
                      <tr>
                        <th>DEVICE IMEI</th>
                        <th>MAKE & MODEL</th>
                        <th>PLATE NUMBER</th>
                        <th>METER READING (KM)</th>
                        <th>GROUP</th>
                        <th>PARTY NAME</th>
                        <th>PARTY ID</th>
                        <th>PARTY TERRITORY</th>
                        {
                          this.state.company.companyId === "66fd17422e6f872ff9a43886" && (
                            <>
                              <th>REGION</th>
                              <th>AREA</th>
                            </>
                          )
                        }
                        {/* <th>TAGS</th> */}

                      </tr>
                    </thead>
                    <tbody>
                      {this.state.things && this
                        .state
                        .things
                        .map((item, index) => {
                          return (
                            <tr key={index}>
                              <td >
                                <p className="things-imei"> {item.imei}</p>

                              </td>
                              <td>
                                <TextFieldGroup
                                  onChange={this.onChange.bind(this)}
                                  value={item.vehicleMakeAndModel}
                                  id={index}
                                  key={index}
                                  type={"text"}
                                  field={"vehicleMakeAndModel"}
                                  placeholder={"Enter Car Make"}
                                />

                              </td>
                              <td>
                                <TextFieldGroup
                                  onChange={this.onChange.bind(this)}
                                  value={item.plateNumber}
                                  type={"text"}
                                  id={index}
                                  key={index}
                                  field={"plateNumber"}
                                  placeholder={"Enter Plate Number"}
                                />
                              </td>
                              <td>
                                <TextFieldGroup
                                  onChange={this.onChange.bind(this)}
                                  value={item.odoMeterReading}
                                  type={"number"}
                                  id={index}
                                  key={index}
                                  field={"odoMeterReading"}
                                  placeholder={"Enter ODO Meter"}
                                />
                              </td>

                              <td>
                                {this.state.groupDepthLevel !== 7 && this.props.createGroup ? <TextFieldGroup
                                  onChange={this.onChange.bind(this)}
                                  value={item.group}
                                  type={"text"}
                                  id={index}
                                  key={index}
                                  field={"group"}
                                  placeholder={"Enter Group"}
                                /> : <p>{item.group}</p>}
                              </td>


                              <td>
                                <TextFieldGroup
                                  onChange={this.onChange.bind(this)}
                                  value={item.partyId}
                                  type={"text"}
                                  id={index}
                                  key={index}
                                  field={"partyId"}
                                  placeholder={"Enter Party Id"}
                                />
                              </td>

                              <td>
                                <TextFieldGroup
                                  onChange={this.onChange.bind(this)}
                                  value={item.partyName}
                                  type={"text"}
                                  id={index}
                                  key={index}
                                  field={"partyName"}
                                  placeholder={"Enter Party Name"}
                                />
                              </td>


                              <td>
                                <TextFieldGroup
                                  onChange={this.onChange.bind(this)}
                                  value={item.partyTerritory}
                                  type={"text"}
                                  id={index}
                                  key={index}
                                  field={"partyTerritory"}
                                  placeholder={"Enter Party Territory"}
                                />
                              </td>



                              {
                          this.state.company.companyId === "66fd17422e6f872ff9a43886" && (
                            <>

                              <td>
                                <TextFieldGroup
                                  onChange={this.onChange.bind(this)}
                                  value={item.region}
                                  type={"text"}
                                  id={index}
                                  key={index}
                                  field={"region"}
                                  placeholder={"Enter Region"}
                                />
                              </td>

                              <td>
                                <TextFieldGroup
                                  onChange={this.onChange.bind(this)}
                                  value={item.area}
                                  type={"text"}
                                  id={index}
                                  key={index}
                                  field={"area"}
                                  placeholder={"Enter Area"}
                                />
                              </td>
                            </>
                          )
                        }





                              {/* <td>
                                        <TextFieldGroup
                                            onChange={this.onChange}
                                            value={item.tags}
                                            type={"text"}
                                            id={index}
                                            key={index}
                                            field={"tags"}
                                            placeholder={"Tags"}
                                        />
                                        </td> */}

                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>

                {this.state.savingData ?
                  <div className="saving-spinner">{loader}</div> :
                  <div className="modal-footer">
                    <div>

                      <button className="close-btn" onClick={this.onSubmit.bind(this)}>
                        Save </button>
                      <button className="close-btn" onClick={this.navigateToVehicleList.bind(this)}>
                        Skip</button>
                    </div>
                  </div>
                }
              </div>

              : <div><div className='modal-heading'>

                <h5 className="car-and-device-assig">Vehicle And Device Assignment</h5>
                <p className="welcome-to-auxo-flee">No more devices available to assign vehicles<br /> Please contact admin for more info! </p>


              </div>
                <div className="modal-footer">
                  <div>
                    <button className="close-btn" onClick={this.navigateToVehicleList.bind(this)}>
                      Close
                    </button>
                  </div> </div>
              </div>}
          </div> : [loader]}
      </Modal>

    );
  }
}

VehicleAssignmentModal.propTypes = {};
VehicleAssignmentModal.defaultProps = {};
const mapStateToProps = state => ({ things: state.things.things, createGroup: state.permissions.createGroup });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Things.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(VehicleAssignmentModal);
