import React, { useEffect, useState } from 'react';
import Select, { createFilter } from 'react-select-nested-group';
import { filter, has, includes, map } from 'lodash';

import { getGroupList } from './utils';
import { Group, GroupHeading, Option, ValueContainer } from './component';

import './index.scss'
import { formatGroupList } from "../../utils/helper.util";

const SelectComponent = (props) => {

  const initialState = { options: [], preSelected: [] },
    [state, setState] = useState(initialState);

  const filterConfig = {
    ignoreCase: props.ignoreCase || true,
    ignoreAccents: props.ignoreAccents || false,
    trim: props.trim || true,
    matchFrom: props.matchFromStart ? 'start' : 'any',
  };

  useEffect(
    () => {
      const labelKey = props.optionKeys && props.optionKeys.label ? props.optionKeys.label : 'label',
        valueKey = props.optionKeys && props.optionKeys.value ? props.optionKeys.value : 'value',
        childKey = props.optionKeys && props.optionKeys.child ? props.optionKeys.child: 'child';

      setState(prevState => ({
        ...prevState,
        options: formatGroupList(props.options, { labelKey, valueKey, childKey })
      }));

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.options]
  );

  useEffect(
    () => {
      setState(prevState => ({
        ...prevState,
        preSelected: props.preSelected && (!(has(props.preSelected[0], 'label') || has(props.preSelected[0], 'value')))
          ? map(props.preSelected, option => ({ label: option[props.optionKeys.label], value: option[props.optionKeys.value]}))
          : props.preSelected || []
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.preSelected]
  );

  const onChange = (optionList, event) => {
    let optionListParsed = optionList;
    if (event.action === 'remove-value') {
      const groupList = getGroupList(state.options);
      if (groupList[event.removedValue.value]) optionListParsed = filter(optionList, option => !includes(groupList[event.removedValue.value], option.value));
    }
    props.onChange(optionListParsed);
  };

  const handleInputChange = (inputValue, actionMeta) => {
    console.log("Search input changed:", inputValue);
    if (props.onInputChange) {
      props.onInputChange(inputValue, actionMeta);
    }
    return inputValue;
  };

  return (
    <div className={`form-group ${props.error ? 'has-error' : ''}`}>
      {props.label && <label>{props.label}</label>}
      <Select
        name={props.name}
        placeholder={props.placeholder || 'Search...'}
        isMulti={props.multi || false}
        isClearable={props.clearable || true}
        isSearchable={props.searchable || true}
        isDisabled={props.disabled !== undefined ? props.disabled : false}
        hideSelectedOptions={false}
        filterOption={createFilter(filterConfig)}
        value={state.preSelected}
        options={state.options}
        components={{ Option, GroupHeading, Group, ValueContainer }}
        onChange={onChange}
        onInputChange={handleInputChange}
      />
      {props.error && <span className='text-danger'>{props.error}</span>}
    </div>
  );
};

export default React.memo(SelectComponent);
