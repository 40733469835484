const transformGroupsToOptions = (groups, searchTerm = "") => {
  if (!groups) return [];
  const matchesSearch = (text) =>
    text.toLowerCase().includes(searchTerm.toLowerCase());

  return groups.reduce((acc, group) => {
    const childGroupOptions = transformGroupsToOptions(group.child || [], searchTerm);

    const plateOptions = (group.plateNumbers || [])
      .filter(plate => matchesSearch(plate))
      .map(plate => ({
        label: plate,
        value: plate,
        isDisabled: false,
        className: 'option--is-enabled'
      }));

    const groupMatches = matchesSearch(group.name);
    const allChildren = [...childGroupOptions, ...plateOptions];

    if (groupMatches || allChildren.length > 0) {
      acc.push({
        label: group.name,
        value: group._id,
        isDisabled: true,           
        className: 'group-heading',
        child: allChildren
      });
    }
    return acc;
  }, []);
};

export default transformGroupsToOptions;