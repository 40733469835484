import { map, filter, find } from 'lodash';
import moment from 'moment';

export const formatGroupList = (groupList, optionKeys = { labelKey: 'name', valueKey: 'name', childKey: 'child' }) => {
  return map(groupList, group => {
    // TODO: Fix the value to be _id after API update
    const option = { label: group[optionKeys.labelKey], value: group[optionKeys.valueKey] };
    if (group.child && group.child.length) option.options = formatGroupList(group.child, optionKeys);
    return option;
  })
};

export const filterVehicleList = (vehicleList = [], groupList = [], status = '', selected = []) => {
  selected = selected || [];
  if (!status) {
    status = ''
  }
  if (groupList) {
    if (!groupList.length && !status && !selected.length) {
      return vehicleList;
    }

    if (!groupList.length && !status && selected.length) {
      return filter(
        vehicleList, item => {
          return find(selected, ['value', item._id]) ? true : false
        }
      );
    }
    if (!groupList.length && status && !selected.length) {
      return filter(
        vehicleList, item => {
          return item.vehicleStatus === status.value
        }
      );
    }
    if (groupList.length && !status && !selected.length) {
      return filter(
        vehicleList, item => {
          return find(groupList, ['value', item.groupId || item.group]) ? true : false
        }
      );
    }
  }

  return filter(
    vehicleList, item =>
      !(groupList && groupList.length && !find(groupList, ['value', item.groupId || item.group]))
        ? !(status && item.vehicleStatus !== status.value) && (!selected.length || find(selected, ['value', item._id]))
        : false
  );
};

export const filterVehicleListing = (vehicleList = [], groupList = [], status = [], selected = []) => {
  selected = selected || [];
  groupList = groupList === null ? [] : groupList;
  if (!status) {
    status = []
  }
  if (!groupList) {
    return vehicleList;
  }
  if (!groupList.length && !status.length && !selected.length) {
    return vehicleList;
  }
  if (!groupList.length && !status.length && selected.length) {
    return filter(
      vehicleList, item => {
        return find(selected, ['value', item._id]) ? true : false
      }
    );
  }
  if (!groupList.length && status.length && !selected.length) {
    if (status.includes("WEAK_SIGNAL")) {
      return filter(
        vehicleList,
        (item) =>
          status.includes(item.vehicleStatus) || 
          moment(item.lastObservationTime).isBefore(moment().subtract(2, 'minute'))
      );
    } else {
      return filter(
        vehicleList,
        (item) => status.includes(item.vehicleStatus)
      );
    }
  }
  // if (!groupList.length && status.length && !selected.length) {
  //   return filter(
  //     vehicleList, item => {
  //       return status.includes(item.vehicleStatus)
  //     }
  //   );
  // }
  
  if (groupList.length && !status.length && !selected.length) {
    return filter(
      vehicleList, item => {
        return find(groupList, ['value', item.groupId || item.group]) ? true : false
      }
    );
  }
  return filter(
    vehicleList, item => {
      return !(groupList && groupList.length && !find(groupList, ['value', item.groupId || item.group]))
        ? ((!status.length) || (status && status.includes(item.vehicleStatus))) && (!selected.length || find(selected, ['value', item._id]))
        : false
    }
  );
};
