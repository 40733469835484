import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { clone, map, filter } from "lodash";
import moment from "moment";
import Select from "react-select-nested-group";
import Modal from "react-bootstrap4-modal";
import DateTime from "react-datetime";
import { Element, scroller } from "react-scroll";
import Icon from "react-icons-kit";
import * as R from "ramda";
import TextFieldGroup from "../../common/TextFieldGroup";
import Things from "../../../ducks/Things";
import Company from "../../../ducks/Company";
import Groups from "../../../ducks/Groups";
import GeoFence from '../../../ducks/GeoFence';
import { Spinner } from "../../common/Spinner/index";
import { MapWithAMarkerClusterer } from "../../common/maps/Maps";
import { filterVehicleListing } from "../../../utils/helper.util";
import SelectComponent from "../../select";
import CardsWidget from "./CardWidget";
import HelmetCardsWidget from "./HelmetCardWidget";
import "./Index.css";
import pointerIcon from "../../../assets/img/ic_speedometer_grey.png";
import copyIcon from "../../../assets/img/copyIcon.png";
import pin_moving from "../../../assets/img/pin_moving.png";
import pin_ignition from "../../../assets/img/pin_ignition.png";
import pin_idle_shadow from "../../../assets/img/pin_idle_shadow.png";
import pin_pluggedout from "../../../assets/img/pin_pluggedout.png";
import pin_parked from "../../../assets/img/pin_parked.png";
import last_updated from "../../../assets/img/last_updated.png";
import cold_storage from "../../../assets/img/cold_storage.png";
import battery from "../../../assets/img/battery.png";
import gears from "../../../assets/img/gears.png";
import icons8share from "../../../assets/img/icons8-share.svg";
import noSignal from '../../../assets/img/no signal.png';
import { ic_warning } from "react-icons-kit/md/ic_warning";
import { ic_signal_wifi_off } from 'react-icons-kit/md/ic_signal_wifi_off'

let observationtime = moment().subtract(1, 'minutes');


const vehiclesStatusMaps = {
  "IGNITION_ON": {
    'status': "Ignition",
    'pinIcon': pin_ignition,
    'statusClass': "fa fa-circle circle-green",
    'style': { transform: "rotate(110deg)" }
  },
  "VEHICLE_MOVING": {
    'status': "Moving",
    'pinIcon': pin_moving,
    'statusClass': "fa fa-circle circle-blue"
  },
  "IGNITION_OFF": {
    'status': "Parked",
    'pinIcon': pin_parked,
    'statusClass': "fa fa-circle circle-orange",
  },
  "DEVICE_UNPLUG": {
    'status': "Unplug",
    'pinIcon': pin_pluggedout,
    'statusClass': "fa fa-circle circle-red"
  },
  "DEVICE_PLUG_IN": {
    'status': "Parked",
    'pinIcon': pin_parked,
    'statusClass': "fa fa-circle circle-orange",
  },
  "NEVER_HEARD": {
    'status': "Not Installed",
    'pinIcon': pin_pluggedout,
    'statusClass': "fa fa-circle circle-red"
  },
  "NOT_RESPONDING": {
    'status': "Inactive",
    'pinIcon': pin_pluggedout,
    'statusClass': "fa fa-circle circle-red"
  },
  "SUSPENDED": {
    'status': "Offline",
    'pinIcon': pin_pluggedout,
    'statusClass': "fa fa-circle circle-red"
  },
  "IDLE": {
    'status': "Idle",
    'pinIcon': pin_idle_shadow,
    'statusClass': "fa fa-circle circle-gray",
    'style': {
      transform: "rotate(200deg)",
      marginBottom: "4px",
      width: "20px",
    }
  },
  "HELMET_IDLE": {
    'status': "Idle",
    'pinIcon': pin_idle_shadow,
    'statusClass': "fa fa-circle circle-gray",
    'style': {
      transform: "rotate(200deg)",
      marginBottom: "4px",
      width: "20px",
    }
  },
  "HELMET_NOT_WEARING_DRIVING": {
    'status': "Not wearing but driving",
    'pinIcon': pin_pluggedout,
    'statusClass': "fa fa-circle circle-red",
  },
  "HELMET_WEARING_DRIVING": {
    'status': "Wearing Driving",
    'pinIcon': pin_moving,
    'statusClass': "fa fa-circle circle-blue"
  },
  "HELMET_WEARING": {
    'status': "Wearing at Stop",
    'pinIcon': pin_ignition,
    'statusClass': "fa fa-circle circle-green",
    'style': { transform: "rotate(110deg)" }
  },
  "HELMET_PARKED": {
    'status': "Parked",
    'pinIcon': pin_parked,
    'statusClass': "fa fa-circle circle-orange"
  }
};

const defaultVehicleStatus = {
  'status': "Not Installed",
  'pinIcon': pin_pluggedout,
  'statusClass': "fa fa-circle circle-red",
  'style': {
    transform: "",
    marginBottom: "",
    width: "",
  }
}

var filteredThings;


function radiusToZoom(distance) {
  let radius = distance / 2;
  radius = radius || 5;
  return Math.round(16 - Math.log(radius) / Math.LN2);
}
class MapView extends Component {
  constructor(props) {
    super(props);

    const companyInfo = JSON.parse(localStorage.getItem("companyInfo"));

    let selectedStatus = [];
    let movingTracks = [];

    this.state = {
      selectedObj: null,
      weakSignal: 0,
      selectedGroup: [],
      selectedGeofence: [],
      selectedStatus,
      sharedURL: '',
      startSelectedDate: moment(),
      endSelectedDate: moment(),
      shareVehicle: {},
      selectedGeoFenceObject: {},
      isSettingsVisible: false,
      shareModal: false,
      geoFenceSelected: false,
      defaultSearchValue: null,
      searchValue: [],
      searchTerm: "",
      defaultCenter: {
        lat: 30.1575,
        lng: 71.5249,
      },
      vehicleLocation: {
        lat: 30.1575,
        lng: 71.5249,
      },
      zoom: 5.0,
      vehicleNumberPlate: "",
      loading: true,
      // isTrafficeLayerEnable: false,
      isLabelEnable: false,
      polygon: false,
      selectedVehicle: null,
      searchVehicleValues: [],
      groupId: companyInfo ? companyInfo.groupId : "",
      companyId: companyInfo ? companyInfo.companyId : "",
      geofence: false,
      routefence: false,
      mapLoading: false,
      todayTrack: [],
      selectedThing: null,

      todayEvents: {},
      todayStops: [],
      tracks: [],
      movingTracks: [],
      trackFootprints: false,
      deletedThings: []
    };
    this.displayModel = this.displayModel.bind(this);
    this.hideModel = this.hideModel.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  async componentDidMount() {
    if (this.state.groupId !== "") {


      this.props.actions.getMqtt({ groupId: this.state.groupId });

      let geofenceActions =
        await this
          .props
          .geofenceActions
          .getGroupGeoFences2();

      const actions = [this.props.actions.fetchThings(true)];

      const { companyId } = this.state;
      if (companyId === undefined) {
        companyId = JSON.parse(localStorage.getItem("companyInfo")).companyId;
      }

      if (companyId !== "5d35f47659712845bb18a9d1" && companyId !== "60efe1d1754fed771258f000") {
        actions.push(this.props.actions.getSummaryWidgets());
      }
      if (companyId === "60efe1d1754fed771258f000") {
        actions.push(this.props.actions.getHelmetSummaryWidgets());
      }

      let selectedStatus = [];
      if (this.props.selectedStatus) {
        selectedStatus = map(this.props.selectedStatus, (item) => {
          return { value: item, label: item };
        });
      }

      if (!(this.props.groupList && this.props.groupList.length)) {
        actions.push(
          this.props.groupActions.getSubGroups(this.state.groupId)
        );
      }
      await Promise.all(actions).then(() =>
        this.setState({
          ...this.state,
          loading: false,
          selectedStatus,
          searchVehicleValues: map(this.props.things, (item) => ({
            value: item._id,
            label: item.plateNumber || item.imei.substr(-8),
            item: item,
          })),
        })
      );
    }

    this.interval = setInterval(this.refreshData, 10000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.center) !== JSON.stringify(this.props.center) &&
      this.state.trackFootprints && this.props.selectedVehicleCords && this.props.selectedVehicleCords.lat
    ) {

      this.setState({
        ...this.state,
        movingTracks: [...this.state.movingTracks, this.props.selectedVehicleCords],
      });
    }
  }
  componentWillUnmount() {

    this.props.actions.disconnectMqtt();

  }

  updateSelection = async (selectedOption) => {
    this.setState({
      ...this.state, selectedObj: selectedOption
    })

    this.searchVehicle(selectedOption);
  }

  async refreshData() {
    const { selectedObj } = this.state;
    if (selectedObj) {
      //console.log("REFRESH: ", selectedObj);
      //await this.searchVehicle(this.state.selectedObj);

      //this.props.actions.getMqtt({ groupId: this.state.groupId });
      //const thingId = this.state.selectedObj.vehicle.name;
      //console.log("FOUND THING Update: ", foundThing);
    }
  }

  searchVehicle = async (selectedOption) => {
    const thingId = selectedOption ? selectedOption.value : null;
    if (thingId) {
      let stateTemp = {
        thingId,
        startTime: new Date(selectedOption.vehicle.lastObservationTime).toISOString(),
        endTime: new Date().toISOString()
      };
      let foundThing = await this.props.actions.fetchThingSingle(stateTemp);
      let things = clone(this.props.things);
      // let vehicleLocation = filter(things, (item) => {
      //   if (item._id === thingId) return foundThing;
      // });
      let vehicleLocation = [foundThing]
      if (vehicleLocation.length) {
        await this.props.actions.getSummaryWidgets(thingId, vehicleLocation.name);

        this.setState({
          ...this.state,
          defaultCenter: {
            lat: vehicleLocation[0].latitude,
            lng: vehicleLocation[0].longitude,
          },
          defaultSearchValue: selectedOption,
          zoom: 17,
          vehicleNumberPlate: selectedOption.value,
          selectedThing: vehicleLocation[0],
          todayTrack: [],
          todayEvents: {},
          todayStops: [],
          movingTracks: [],
        });
        this.props.actions.ChangeCenter({
          lat: vehicleLocation[0].latitude,
          lng: vehicleLocation[0].longitude,
        });

        scroller.scrollTo(thingId, {
          duration: 1500,
          smooth: true,
          containerId: "containerElement",
          offset: -120,
        });
      }
    } else {
      this.props.actions.getSummaryWidgets();
      this.setState({
        ...this.state,
        searchTerm: "",
        defaultCenter: {
          lat: 30.1575,
          lng: 71.5249,
        },

        zoom: 5.0,
        vehicleNumberPlate: "",
        selectedVehicle: null,
        selectedThing: null,
        defaultSearchValue: null,
      });
      this.props.actions.ChangeCenter({
        lat: 30.1575,
        lng: 71.5249,
      });
    }
  };

  selectedGeofences = (selectedGeoFence) => {

    if (selectedGeoFence && selectedGeoFence.length) {
      let polygon = false;
      let selectedGeoFenceCenter = this.props.geoFencesWpaging.filter((item) => {

        if (item._id === selectedGeoFence[0].value) {
          if (item.geoFenceType === "POLYGON") {
            polygon = true;
          }
          return true;
        }
      });

      this.setState({ ...this.state, selectedGeoFence, polygon: polygon, geoFenceSelected: true, 'selectedGeoFenceObject': selectedGeoFenceCenter[0] });
    } else {
      this.setState({ ...this.state, selectedGeoFence, geoFenceSelected: false });
    }
  }
  selectGroup = (selectedGroup) => { 
    this.setState({ selectedGroup  })
  } ;
  selectStatus = async (selectedOption) => {
    if (selectedOption && selectedOption[0]?.label === "NOT INSTALLED") {
      this.setState({ selectedStatus: selectedOption });
      const deletedThings = await this.props.actions.getDeletedThings(this.state.companyId)
      this.setState({ deletedThings });
      return
    }
    this.setState({ selectedStatus: selectedOption, deletedThings: [] });
  }

  onCenterChanged(map) {
    this.props.actions.ChangeCenter({
      lat: map.getCenter().lat(),
      lng: map.getCenter().lng(),
    });
  }

  displayModel() {
    this.setState({
      isSettingsVisible: true,
    });
  }

  hideModel() {
    this.setState({
      isSettingsVisible: false,
    });
  }

  async saveSettings(settings) {
    await this.props.actions.setSummaryWidgets(settings);
    this.hideModel();
  }

  async cancelModal() {
    this.hideModel();
  }

  formatVehicleList() {
    // return map(filterVehicleListing(this.props.things, this.state.selectedGroup, this.state.selectedStatus, []), item => ({
    //   value: item._id,
    //   label: item.plateNumber || item.imei.substr(-8),
    //   item: item
    // }))
    return map(this.props.things, (item) => ({
      value: item._id,
      label: item.plateNumber || item.imei.substr(-8),
      item: item,
    }));
  }
  filterVehicle = async (value) => {
    await this.setState({ ...this.state, searchValue: value });
    if (!value || (value && !value.length)) {
      this.searchVehicle({})
    }
  };
  // changeCheckValue = (target) => {

  //   if (target.name === 'isLabelEnable') {
  //     this.setState({ ...this.state, isLabelEnable: !this.state.isLabelEnable })
  //   } else if (target.name === 'isTrafficeLayerEnable') {
  //     this.setState({ ...this.state, isTrafficeLayerEnable: !this.state.isTrafficeLayerEnable })
  //   }

  // };

  changeGeofence = async (e) => {
    let checked = e.target.checked;
    if (checked) {
      this.setState({
        ...this.state,
        mapLoading: true,
      });
      await this.props.companyActions.getGeofence();
    }
    this.setState({
      ...this.state,
      geofence: checked,
      mapLoading: false,
    });
  };

  changeRoutefence = async (e) => {
    let checked = e.target.checked;
    if (checked) {
      this.setState({
        ...this.state,
        mapLoading: true,
      });
      await this.props.companyActions.getRoutefence();
    }
    this.setState({
      ...this.state,
      routefence: checked,
      mapLoading: false,
    });
  };
  changeFootprints = (e) => {
    this.setState({
      trackFootprints: e.target.checked,
      movingTracks: [],
    });
  };

  shareLocations = (item) => {
    this.setState({
      ...this.state,
      shareModal: true,
      shareVehicle: item
    });
  }

  setStartSelectedDate = async (item) => {
    this.setState({
      ...this.state,
      startSelectedDate: item,
    });
  };

  endStartSelectedDate = async (item) => {
    this.setState({
      ...this.state,
      endSelectedDate: item,
    });
  };

  onSubmit = async () => {
    let shareData = {
      'shareVehicle': this.state.shareVehicle,
      'startSelectedDate': this.state.startSelectedDate,
      'endSelectedDate': this.state.endSelectedDate,
      'groupId': this.state.groupId
    };
    let url = await this.props.actions.setSharedUrl(shareData);
    let sharedURL = `${window.location.origin}/guest/mapShare/${url['_id']}`;
    this.setState({ ...this.state, sharedURL });
  };

  async handleDoubleClick(item) {
    if (this.props.permissions.companyFeatures.indexOf("PLAYBACK") !== -1) {
      this.setState({
        ...this.state,
        mapLoading: true,
      });
      const interval = {
        startDate: moment()
          .startOf("day")
          .format(),
        endDate: moment().format(),
      };
      const data =
        (await this.props.actions.getTrackPoints(
          JSON.stringify({ interval }),
          item._id
        )) || {};
      let track = data.path.map((point) => point.latlng);
      const distance = data.distance;
      const zoom = parseInt(radiusToZoom(distance), 10);

      this.setState({
        ...this.state,
        todayTrack: track,
        todayEvents: data.eventsListing,
        todayStops: data.stops || [],
        mapLoading: false,
        tracks: data.path,
        zoom,
        searchValue: [{ item, label: item.plateNumber, value: item._id }]
      });
    }
  }

  closeShareModal() {
    this.setState({ ...this.state, shareModal: false, shareVehicle: {} });
  }

  copyShareURL() {
    navigator.clipboard.writeText(this.state.sharedURL);
  }

  onChange() {
    // this.setState({...this.state,sharedURL: })
  }

  render() {
    let loader = <Spinner key="1" />;
    const { widgets = {}, helmetWidgets } = this.props;

    const { vehicleStateSummary = {} } = widgets;
    const { vehicleStateSummary: helmetVehicleStateSummary = {} } = helmetWidgets;
    const isSuperAdminUser = localStorage.getItem("isSuperAdminUser");
    const permissions = this.props.permissions;
    const { selectedThing } = this.state;
    if (selectedThing && this.props.selectedVehicleCords && this.props.selectedVehicleCords.lat && selectedThing._id === this.props.selectedVehicleCords.id) {
      selectedThing.latitude = this.props.selectedVehicleCords.lat
      selectedThing.longitude = this.props.selectedVehicleCords.lng
      selectedThing.angle = this.props.selectedVehicleCords.angle
      selectedThing.location = this.props.selectedVehicleCords.location
      selectedThing['oldangle'] = selectedThing.angle
    }
    const {
      DEVICE_UNPLUG: unplugCount = 0,
      IGNITION_OFF: parkedCount = 0,
      DEVICE_PLUG_IN: pluginCount = 0,
      IGNITION_ON: ignitionCount = 0,
      VEHICLE_MOVING: movingCount = 0,
      NEVER_HEARD: neverHeard = 0,
      NOT_RESPONDING: notResponding = 0,
      IDLE: idleCount = 0,
      SIM_STATUS: simstatus = 0,
      totalVehicles: totalVehicles = 0,
    } = vehicleStateSummary;
    const {
      HELMET_IDLE: helmetIdleCount = 0,
      HELMET_NOT_WEARING_DRIVING: helmetNotWearingDriving = 0,
      HELMET_WEARING_DRIVING: helmetWearingDriving = 0,
      DEVICE_PLUG_IN: helmetPluginCount = 0,
      HELMET_WEARING: helmetWearingStop = 0,
      HELMET_PARKED: helmetParked = 0,
      DEVICE_UNPLUG: helmetUnplugCount = 0,
      NEVER_HEARD: helmetNeverHeard = 0,
      NOT_RESPONDING: helmetNotResponding = 0,
      totalVehicles: totalhelmet = 0,
    } = helmetVehicleStateSummary;


    let status = [
      { label: "UNPLUG", value: "DEVICE_UNPLUG" },
      { label: "IGNITION", value: "IGNITION_ON" },
      { label: "MOVING", value: "VEHICLE_MOVING" },
      { label: "PARKED", value: "IGNITION_OFF" },
      { label: "WEAK SIGNAL", value: "WEAK_SIGNAL" },
      //{ label: "NOT INSTALLED", value: "NEVER_HEARD" },
      { label: "INACTIVE", value: "NOT_RESPONDING" },
      { label: "OFFLINE", value: "SUSPENDED" },
      { label: "IDLE", value: "IDLE" },
    ];
    if (this.state.companyId === "664f4f93e440b860d2c1c93c") {
      status = [
        { label: "UNPLUG", value: "DEVICE_UNPLUG" },
        { label: "IGNITION", value: "IGNITION_ON" },
        { label: "MOVING", value: "VEHICLE_MOVING" },
        { label: "PARKED", value: "IGNITION_OFF" },
        { label: "WEAK SIGNAL", value: "WEAK_SIGNAL" },
        { label: "NOT INSTALLED", value: "NEVER_HEARD" },
        { label: "INACTIVE", value: "NOT_RESPONDING" },
        { label: "OFFLINE", value: "SUSPENDED" },
        { label: "IDLE", value: "IDLE" },
      ];
    }
    if (companyId === "60efe1d1754fed771258f000") {
      status = [
        { label: "UNPLUG", value: "DEVICE_UNPLUG" },
        { label: "INACTIVE", value: "NOT_RESPONDING" },
        { label: "IDLE", value: "HELMET_IDLE" },
        { label: "Helmet Not Wearing But Driving", value: "HELMET_NOT_WEARING_DRIVING" },
        { label: "Helmet Wearing Driving", value: "HELMET_WEARING_DRIVING" },
        { label: "Helmet Wearing But Stop", value: "HELMET_WEARING" },
        { label: "Parked", value: "HELMET_PARKED" },
      ];

    }


    filteredThings = filterVehicleListing(
      this.props.things,
      this.state.selectedGroup,
      map(this.state.selectedStatus, "value"),
      this.state.searchValue,
    );
   
    let weakSignal = 0;

    //  let isSmooth = false;

    // if (selectedThing && selectedThing.lastObservationTime ){
    //   let diff = moment(selectedThing.lastObservationTime).diff(observationtime,'seconds');
    //   if (diff < 30) {

    //     isSmooth = true
    //   }
    //   observationtime = moment(selectedThing.lastObservationTime)
    // }
    const { companyId } = this.state;

    return (
      <>
        <Modal
          visible={this.state.shareModal}
          onClickBackdrop={this.modalBackdropClicked}
          dialogClassName="driver-modal-dialog"
        >
          <div>
            <button
              type="button"
              className="close close-x"
              aria-label="Close"
              onClick={this.closeShareModal.bind(this)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-heading row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              {" "}
              {this.state.sharedURL ? <p className="driver-heading">Share this Url</p> : <p className="driver-heading">Set Time Range</p>}
            </div>
          </div>
          <div className="modal-body">
            {!this.state.sharedURL &&
              <div className="row">
                <div className="col-lg-4 col-sm-4">
                  <label>Start Time</label>
                  <DateTime
                    value={this.state.startSelectedDate}
                    dateFormat="DD-MM-YYYY"
                    timeFormat="HH:mm"
                    onChange={this.setStartSelectedDate}
                  />
                </div>
                <div className="col-lg-4 col-sm-4">
                  <label>End Time</label>
                  <DateTime
                    value={this.state.endSelectedDate}
                    dateFormat="DD-MM-YYYY"
                    timeFormat="HH:mm"
                    onChange={this.endStartSelectedDate}
                  />
                </div>
                <div className="col-lg-3" style={{ padding: "25px 0 0 25px" }}>
                  <button
                    className={`btn btn-primary search-btn search-button`}
                    onClick={this.onSubmit}
                  >
                    Create
                  </button>
                </div>
              </div>
            }
            {this.state.sharedURL && <div className="row">
              <div className="col-lg-12 SharedURL">
                <div className="textField">
                  <TextFieldGroup
                    field={"SharedURL"}
                    placeholder={"SharedURL"}
                    value={this.state.sharedURL}
                    type={"text"}
                    label={"Url"}
                    onChange={this.onChange.bind(this)}
                  />
                </div>
                <div className="copyBtn">
                  <span style={{ cursor: "pointer" }} className="">
                    <img src={copyIcon} onClick={this.copyShareURL.bind(this)} style={{ marginTop: "35px" }} alt='' />
                  </span>
                </div>
              </div>
            </div>}
          </div>
        </Modal>
        <div>
          {this.state.loading ? (
            [loader]
          ) : (
            <div>
              <div className="row">
                {/* <div className='col-md-3'>
              
              <div className='filter m-t-7'>
                              <input type='checkbox' name={'isTrafficeLayerEnable'} checked={this.state.isTrafficeLayerEnable} onChange={e => {
                                const { target } = e;
                                this.changeCheckValue(target)
                              }} value={this.state.isTrafficeLayerEnable} /> Traffic Layer
                                </div>
              </div> */}

                <div className="col-md-3">
                  <SelectComponent
                    field="form-field-name"
                    placeholder="Select Geofence"
                    preSelected={this.state.selectedGeoFence}
                    optionKeys={{ label: "name", value: "_id" }}
                    options={this.props.geoFencesWpaging}
                    onChange={this.selectedGeofences}
                  />
                </div>
                {companyId !== "5d35f47659712845bb18a9d1" && (
                  <div className="col-md-3">
                    {permissions.companyFeatures.indexOf("GROUPS") !== -1 && (
                      <SelectComponent
                        field="form-field-name"
                        placeholder="Select Group"
                        preSelected={this.state.selectedGroup}
                        multi={true}
                        optionKeys={{ label: "name", value: "_id" }}
                        options={this.props.groupList}
                        onChange={this.selectGroup}
                      />
                    )}
                  </div>
                )}

                <div className="col-md-3">
                  <div>
                    {companyId !== "5fd0a1fb6dcde56e0a6c1f9e" &&
                      companyId !== "603e07546a23817d9e06a2c4" &&
                      companyId !== "5d35f47659712845bb18a9d1" && (
                        <div className="form-group">
                          <Select
                            name="form-field-name"
                            isClearable={true}
                            isSearchable={true}
                            isMulti
                            placeholder="Select Status"
                            onChange={this.selectStatus}
                            value={this.state.selectedStatus}
                            options={status}
                          />
                        </div>
                      )}
                  </div>
                </div>
                {companyId !== "5d35f47659712845bb18a9d1" && (
                  <div className="col-md-3">
                    <div>
                      <div className="form-group">
                        <Select
                          name="form-field-name"
                          isClearable={true}
                          isMulti
                          isSearchable={true}
                          placeholder="Search"
                          onChange={this.filterVehicle}
                          value={this.state.searchValue}
                          options={this.state.searchVehicleValues}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-9 cluster-map-view">
                  {(permissions.companyFeatures.indexOf("GEO-FENCE") !== -1 ||
                    permissions.companyFeatures.indexOf("ROUTE-FENCE") !== -1) &&
                    (permissions.viewGeoFence ||
                      permissions.viewRouteFence ||
                      isSuperAdminUser) ? (
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div className="m-b-10-px">
                        {permissions.companyFeatures.indexOf("GEO-FENCE") !==
                          -1 &&
                          (permissions.viewGeoFence || isSuperAdminUser) && (
                            <span>
                              <input
                                type="checkbox"
                                checked={this.state.geofence}
                                onChange={this.changeGeofence.bind(this)}
                              />{" "}
                              Geo-fence
                            </span>
                          )}
                        {permissions.companyFeatures.indexOf("ROUTE-FENCE") !==
                          -1 &&
                          (permissions.viewRouteFence || isSuperAdminUser) && (
                            <span className="m-l-20-px">
                              <input
                                type="checkbox"
                                checked={this.state.routefence}
                                onChange={this.changeRoutefence.bind(this)}
                              />{" "}
                              Route-fence
                            </span>
                          )}
                      </div>
                      <div className="m-b-10-px">
                        <span className="m-l-20-px">
                          <input
                            type="checkbox"
                            checked={this.state.trackFootprints}
                            onChange={this.changeFootprints.bind(this)}
                          />{" "}
                          Track Footprints
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div>
                    {this.state.mapLoading ? (
                      [loader]
                    ) : (
                      <>
                        <MapWithAMarkerClusterer
                          markers={selectedThing ? [selectedThing] : filteredThings}
                          smoothCall={false}
                          zoom={this.state.geoFenceSelected ? 11.5 : this.state.selectedObj ? 13 : this.state.zoom}
                          center={{
                            lat: this.state.geoFenceSelected ? this.state.selectedGeoFenceObject.center[1] : this.props.center?.lat,
                            lng: this.state.geoFenceSelected ? this.state.selectedGeoFenceObject.center[0] : this.props.center?.lng,
                          }}
                          defaultCenter={{
                            lat: this.state.defaultCenter.lat,
                            lng: this.state.defaultCenter.lng,
                          }}
                          isColdStorage={this.props.permissions.companyFeatures.indexOf("TEMPERATURE") !== -1}
                          onCenterChanged={this.onCenterChanged.bind(this)}
                          onVehicleClick={this.searchVehicle.bind(this)}
                          defaultSearchValue={this.state.defaultSearchValue}
                          showGeofence={this.state.geoFenceSelected ? true : this.state.geofence}
                          geofence={this.state.geoFenceSelected ? [this.state.selectedGeoFenceObject] : this.props.company.geofence}
                          showRoutefence={this.state.routefence}
                          routefence={this.props.company.routefence}
                          track={this.state.todayTrack}
                          events={this.state.todayEvents}
                          stops={this.state.todayStops}
                          trackFootprints={this.state.trackFootprints}
                          tracks={this.state.movingTracks}
                          polygon={this.state.polygon}
                          polygonPath={this.state.polygon ? this.state.selectedGeoFenceObject.polygonPath : []}
                        // isTrafficeLayerEnable={this.state.isTrafficeLayerEnable}
                        />
                      </>)}
                  </div>
                </div>

                <div className="col-md-3 ">
                  <div
                    className="things-listing-with-maps element m-t-20-px"
                    id="containerElement"
                  >

                    {this.state.deletedThings.length > 0 &&
                      this.state.deletedThings.map((item, index) => {
                        const vehicleStatus = "NEVER_HEARD";
                        let { status, statusClass, pinIcon } = vehiclesStatusMaps[vehicleStatus] || defaultVehicleStatus;
                        return (
                          <Element
                            className={`element section-side ${this.state.vehicleNumberPlate === item._id ? " selected" : ""}`}
                            key={index}
                            name={item._id}
                          >
                            <div className="d-flex justify-content-between align-item-center w-100 m-b-0">
                              <div>
                              <span className="textbold">
                                {item.plateNumber || item.imei.substr(-8)}
                              </span>
                              <br></br>
                              <span className="simple-txt">
                                Last update:
                                <span>
                                  {item.lastStatusChange
                                    ? moment(item.lastStatusChange).format(
                                      "DD/MM/YYYY"
                                    )
                                    : "N/A"}
                                </span>
                              </span>
                              </div>
                              <div>
                              <span className="pull-right list-status">
                                <i className={"fa fa-circle circle-red"} />
                                {status || "Not Installed"}
                                <br></br>
                                <span className="simple-txt">
                                  Deleted at:
                                  <span>
                                    {item.createdAt
                                      ? moment(item.createdAt).format(
                                        "DD/MM/YYYY"
                                      )
                                      : "N/A"}
                                  </span>
                                </span>

                              </span>
                              </div>
                            </div>
                          </Element>
                        );
                      })
                    }



                    {map(filteredThings, (item, index) => {
                      //let style = {},
                      const vehicleStatus = item.vehicleStatus;
                      let { status, statusClass, pinIcon } = vehiclesStatusMaps[vehicleStatus] || defaultVehicleStatus;
                      if (status === "Parked") {
                        if (moment(item.lastObservationTime).isBefore(moment().subtract(1, 'hour'))) {
                          weakSignal++;
                        }
                      } else {
                        if (status !== "Inactive" && moment(item.lastObservationTime).isBefore(moment().subtract(2, 'minutes'))) {
                          weakSignal++;
                        }
                      }
                      let style = vehiclesStatusMaps[vehicleStatus]['style'] || defaultVehicleStatus['style'];

                      // status = "Unplug",
                      // statusClass = "fa fa-circle",
                      // pinIcon = pin_moving;

                      // switch (vehicleStatus) {
                      //   case "IGNITION_ON":
                      //     status = "Ignition";
                      //     pinIcon = pin_ignition;
                      //     statusClass = "fa fa-circle circle-green";
                      //     style = { transform: "rotate(110deg)" };
                      //     break;
                      //   case "VEHICLE_MOVING":
                      //     status = "Moving";
                      //     pinIcon = pin_moving;
                      //     statusClass = "fa fa-circle circle-blue";
                      //     break;
                      //   case "IGNITION_OFF":
                      //     status = "Parked";
                      //     pinIcon = pin_parked;
                      //     statusClass = "fa fa-circle circle-orange";
                      //     break;
                      //   case "DEVICE_UNPLUG":
                      //     status = "Unplug";
                      //     pinIcon = pin_pluggedout;
                      //     statusClass = "fa fa-circle circle-red";
                      //     break;
                      //   case "DEVICE_PLUG_IN":
                      //     status = "Parked";
                      //     pinIcon = pin_parked;
                      //     statusClass = "fa fa-circle circle-orange";
                      //     break;
                      //   case "NEVER_HEARD":
                      //     status = "Not Installed";
                      //     pinIcon = pin_pluggedout;
                      //     statusClass = "fa fa-circle circle-red";
                      //     break;
                      //   case "NOT_RESPONDING":
                      //     status = "Inactive";
                      //     pinIcon = pin_pluggedout;
                      //     statusClass = "fa fa-circle circle-red";
                      //     break;
                      //   case "SUSPENDED":
                      //     status = "Offline";
                      //     pinIcon = pin_pluggedout;
                      //     statusClass = "fa fa-circle circle-red";
                      //     break;
                      //   case "IDLE":
                      //     status = "Idle";
                      //     pinIcon = pin_idle_shadow;
                      //     statusClass = "fa fa-circle circle-gray";
                      //     style = {
                      //       transform: "rotate(200deg)",
                      //       marginBottom: "4px",
                      //       width: "20px",
                      //     };
                      //     break;


                      //   case "HELMET_IDLE":
                      //     status = "Idle";
                      //     pinIcon = pin_idle_shadow;
                      //     statusClass = "fa fa-circle circle-gray";
                      //     style = {
                      //       transform: "rotate(200deg)",
                      //       marginBottom: "4px",
                      //       width: "20px",
                      //     };
                      //     break;
                      //   case "HELMET_NOT_WEARING_DRIVING":
                      //     status = "Not wearing but driving";
                      //     pinIcon = pin_pluggedout;
                      //     statusClass = "fa fa-circle circle-red";
                      //     break;
                      //   case "HELMET_WEARING_DRIVING":
                      //     status = "Wearing Driving";
                      //     pinIcon = pin_moving;
                      //     statusClass = "fa fa-circle circle-blue";
                      //     break;
                      //   case "HELMET_WEARING":
                      //     status = "Wearing at Stop";
                      //     pinIcon = pin_ignition;
                      //     statusClass = "fa fa-circle circle-green";
                      //     style = { transform: "rotate(110deg)" };
                      //     break;
                      //   case "HELMET_PARKED":
                      //     status = "Parked";
                      //     pinIcon = pin_parked;
                      //     statusClass = "fa fa-circle circle-orange";
                      //     break;

                      //   default:
                      //     break;
                      // }

                      if (this.props.permissions.companyFeatures.indexOf("TEMPERATURE") !== -1) {
                        status = "";
                        statusClass = "";
                        pinIcon = cold_storage;
                        style = { paddingRight: "4px", marginTop: "0px" };
                      }
                      if (companyId === "603e07546a23817d9e06a2c4") {
                        status = "";
                        statusClass = "";
                        pinIcon = battery;
                        style = { paddingRight: "4px", marginTop: "0px" };
                      }
                      const engineStatus = item.engineStatus;
                      const isEngineAwaiting = R.or(
                        R.equals(engineStatus, "awaiting_kill"),
                        R.equals(engineStatus, "awaiting_release")
                      ),
                        isEngineKilled = R.equals(engineStatus, "killed");
                      const engineColor = isEngineAwaiting
                        ? "#FFA500"
                        : isEngineKilled
                          ? "#FF0000"
                          : "#03ABCE";
                      return (
                        <Element
                          className={`element section-side ${this.state.vehicleNumberPlate === item._id
                            ? " selected"
                            : ""
                            }`}
                          key={index}
                          name={item._id}
                          onClick={this.updateSelection.bind(this, {
                            value: item._id,
                            label: item.plateNumber,
                            vehicle: item,
                          })}
                          onDoubleClick={() => this.handleDoubleClick(item)}
                        >
                          <p className="m-b-0">
                            <span className="section-icons">
                              {status === "Parked" ? (
                                moment(item.lastObservationTime).isBefore(moment().subtract(60, 'minutes')) ? (
                                  <img
                                    className="inline-block pin-icon-list grey-out"
                                    style={style}
                                    src={pinIcon}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    className="inline-block pin-icon-list"
                                    style={style}
                                    src={pinIcon}
                                    alt=""
                                  />
                                )
                              ) : status !== "Inactive" ? (
                                moment(item.lastObservationTime).isBefore(moment().subtract(2, 'minutes')) ? (
                                  <img
                                    className="inline-block pin-icon-list grey-out"
                                    style={style}
                                    src={pinIcon}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    className="inline-block pin-icon-list"
                                    style={style}
                                    src={pinIcon}
                                    alt=""
                                  />
                                )
                              ) : status === "Inactive" && (
                                <img
                                  className="inline-block pin-icon-list"
                                  style={style}
                                  src={pinIcon}
                                  alt=""
                                />
                              )}
                            </span>

                            <span className="textbold">
                              {item.plateNumber || item.imei.substr(-8)}
                            </span>
                            {(permissions.companyFeatures.indexOf("LOCATIONSHARE") !== -1 && (<span onClick={() => this.shareLocations(item)}
                              className="textbold">
                              <img
                                className="inline-block pin-icon-list"
                                style={{ "width": "14px" }}
                                src={icons8share}
                                alt=""
                              />
                            </span>))}
                            {status === "Parked" ? (
                              moment(item.lastObservationTime).isBefore(moment().subtract(1, 'hour')) ? (
                                <span className="pull-right list-status">
                                  <i className={"fa fa-circle circle-gray"} />
                                  {status}
                                </span>
                              ) : (
                                <span className="pull-right list-status">
                                  <i className={statusClass} />
                                  {status}
                                </span>
                              )
                            ) : status !== "Inactive" ? (
                              moment(item.lastObservationTime).isBefore(moment().subtract(2, 'minutes')) ? (
                                <span className="pull-right list-status">
                                  <i className={"fa fa-circle circle-gray"} />
                                  {status}
                                </span>
                              ) : (
                                <span className="pull-right list-status">
                                  <i className={statusClass} />
                                  {status}
                                </span>
                              )
                            ) : status === "Inactive" && (
                              <span className="pull-right list-status">
                                <i className={statusClass} />
                                {status}
                              </span>
                            )}


                            {permissions.companyFeatures.indexOf(
                              "ENGINE:KILL"
                            ) !== -1 &&
                              permissions.engineKill && (
                                <span className="pull-right list-status">
                                  <Icon
                                    size={16}
                                    icon={ic_warning}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "20px",
                                      height: "20px",
                                      color: engineColor,
                                    }}
                                    data-toggle="tooltip"
                                    title={item.engineStatus}
                                  />
                                </span>
                              )}
                          </p>
                          {this.state.vehicleNumberPlate === item._id &&
                            status === "Moving" && (
                              <p className="vehicle-info">
                                <span className="section-icons">
                                </span>
                                <span className="simple-txt">
                                  Speed:<span>{item.speed || 0}</span>
                                </span>
                              </p>
                            )}

                          {this.state.vehicleNumberPlate === item._id && (
                            <>
                              <p className="vehicle-info">
                                <span className="section-icons">
                                  <img
                                    className="section-list-icons"
                                    src={last_updated}
                                    alt=""
                                  />
                                </span>
                                <span className="simple-txt">
                                  Last update:
                                  <span>
                                    {item.lastObservationTime
                                      ? moment(item.lastObservationTime).format(
                                        "DD/MM/YYYY, h:mm:ss a"
                                      )
                                      : "N/A"}
                                  </span>
                                </span>

                              </p>
                              {/* <p className="vehicle-info">
                                <span className="section-icons">
                                  <img
                                    className="section-list-icons"
                                    src={last_updated}
                                    alt=""
                                  />
                                </span>
                                <span className="simple-txt">
                                  Status change:
                                <span>
                                    { item.lastStatusChange
                                      ? moment(item.lastStatusChange).format(
                                        "DD/MM/YYYY \n h:mm a"
                                      )
                                      : "N/A"}
                                  </span>
                                </span>
                                </p> */}
                              {companyId === '606b15ed8d1b9f7336b0bee5' &&

                                <p className="vehicle-info">
                                  <span className="section-icons">
                                    <img
                                      style={{ width: 22, marginLeft: -3 }}
                                      src={gears}
                                      alt=""
                                    />
                                  </span>
                                  <span className='simple-txt'>
                                    Engine Temperature
                                    <span>{`${item.engineTemperature}°C` || 'N/A'}</span>
                                  </span>

                                </p>
                              }
                            </>
                          )}

                        </Element>
                      );
                    })}
                  </div>
                </div>
              </div>
              {permissions.companyFeatures.indexOf("DASHBOARD:WIDGETS") !== -1 &&
                !this.state.vehicleNumberPlate || this.props.permissions.companyFeatures.indexOf("TEMPERATURE") !== -1 ? (
                <>
                  {companyId !== "5d35f47659712845bb18a9d1" && companyId !== "60efe1d1754fed771258f000" && (companyId !== "664f4f93e440b860d2c1c93c" || (companyId === "664f4f93e440b860d2c1c93c" && isSuperAdminUser)) && (
                    <CardsWidget
                      unplugCount={unplugCount}
                      parkedCount={parkedCount + pluginCount}
                      idleCount={idleCount}
                      ignitionCount={ignitionCount}
                      movingCount={movingCount}
                      weakSignal={weakSignal}
                      simstatus={simstatus}
                      neverHeard={neverHeard}
                      notResponding={notResponding}
                      totalVehicles={totalVehicles}
                      onLabelClick={this.selectStatus}
                    />
                  )}
                  {companyId === "60efe1d1754fed771258f000" && (

                    <HelmetCardsWidget
                      helmetIdleCount={helmetIdleCount}
                      helmetNotWearingDriving={helmetNotWearingDriving}
                      helmetWearingDriving={helmetWearingDriving}
                      helmetWearingStop={helmetWearingStop}
                      helmetParked={helmetParked + helmetPluginCount}
                      helmetUnplugCount={helmetUnplugCount}
                      helmetNeverHeard={helmetNeverHeard}
                      helmetNotResponding={helmetNotResponding}
                      totalhelmet={totalhelmet}
                      onLabelClick={this.selectStatus}
                    />
                  )}
                </>
              ) :

                this.state.todayTrack.length === 0 && this.state.selectedThing ? (
                  <table className="table-auxo m-t-20-px">
                    <thead>
                      <tr>
                        <th>Plate Number</th>
                        <th>Group</th>
                        <th>Time</th>
                        <th>Location</th>
                        <th>GeoFence</th>
                        <th>Speed</th>
                        <th>Odometer Reading</th>
                        <th>Status</th>
                        <th>
                          <button
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "50%",
                              border: "1px solid #c8c8c8",
                              height: "20px",
                              width: "20px",
                              fontSize: "1rem",
                            }}
                            onClick={
                              this.searchVehicle.bind(this, {})
                            }
                          >
                            &times;
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{this.state.selectedThing.plateNumber}</td>
                        <td>{this.state.selectedThing.group}</td>
                        <td>
                          {moment(
                            this.state.selectedThing.lastObservationTime
                          ).format("DD-MMM-YYYY h:mm a")}
                        </td>
                        <td
                          style={{ textDecoration: "underline", cursor: "pointer" }}
                          onClick={() => this.props.actions.ChangeCenter({
                            lat: this.state.selectedThing.latitude,
                            lng: this.state.selectedThing.longitude
                          })}
                        >{companyId !== '60e3fa684ebc8f1be843dd61' && companyId !== '5f9fe73eb44c0b1a122a38fd' && companyId !== '619f69b25e588e3466add34a' ? `${this.state.selectedThing.latitude},${this.state.selectedThing.longitude}` : `${this.state.selectedThing.location}`} </td>
                        <td>{this.state.selectedThing.geoFence || 'N/A'}</td>
                        <td>{this.state.selectedThing.speed}</td>
                        <td>{this.state.selectedThing.odoMeterReading}</td>
                        <td>{this.state.selectedThing.vehicleStatus}</td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <div
                    className="row"
                    style={{
                      marginTop: "5px",
                      height: "40vh",
                      overflowY: "scroll",
                    }}
                  >
                    <table className="table-dash-obs m-t-20-px">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Date Time</th>
                          <th>Location</th>
                          <th>Speed</th>
                          <th>Angle</th>
                          <th>Status</th>
                          <th>Event</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.tracks.map((point, index) => {
                          return (
                            <tr key={index.toString()}>
                              <td>{index + 1}</td>
                              <td>{point.time}</td>
                              <td>{companyId !== '5f9fe73eb44c0b1a122a38fd' && companyId !== '619f69b25e588e3466add34a' ? `${point.latlng.lat},${point.latlng.lng}` : point.address}</td>
                              <td>{point.speed}</td>
                              <td>{point.angle}</td>
                              <td>{point.status}</td>
                              <td>{point.eventType}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
            </div>
          )}
        </div>
      </>
    );
  }
}

MapView.propTypes = {};
MapView.defaultProps = {};
const mapStateToProps = (state) => {
  return {
    things: state.things.markers,
    geoFencesWpaging: state.geoFence.geoFencesWpaging,
    center: state.things.center,
    observation: state.things.observation,
    widgets: state.things.widgets,
    helmetWidgets: state.things.helmetWidgets,
    widgetsConfig: state.things.widgetsConfig,
    groupList: state.groups.subGroups,
    permissions: state.permissions,
    company: state.company,
    selectedVehicleCords: state.things.selectedVehicleCords
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Things.creators, dispatch),
  groupActions: bindActionCreators(Groups.creators, dispatch),
  companyActions: bindActionCreators(Company.creators, dispatch),
  geofenceActions: bindActionCreators(GeoFence.creators, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(MapView);
